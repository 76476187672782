<template>
	<section>
		<b-navbar v-if="id" toggleable="lg" type="dark" class="p-0" variant="info">
			<b-navbar-brand>
				<div class="d-flex">
					<div class="flex-fill h4 text-truncate">
						Game - {{id}}
					</div>
					<div class="align-self-end h4 pl-4">
						<b-btn class="ml-2" @click="doGetGame()">Get</b-btn>
						<b-btn class="ml-2" @click="doGetGameEx()">GetEx</b-btn>
						<b-dropdown class="ml-2" text="SHOW VERSION">
							<b-dropdown-item v-for="(v, vIndex) in gameStatesVersionNumbers" :key="'stateversion'+vIndex" @click="selectGameStateVersion(v)">
								version - {{v}}
							</b-dropdown-item>
						</b-dropdown>
						<b-badge class="ml-2" title="version">
							Version:
							<template v-if="gameState">
								{{gameState.version}}
							</template>
						</b-badge>
						<b-badge class="ml-2" title="game number">
							GameNumber:
							<template v-if="gameState">
								{{gameState.gameNumber}}
							</template>
						</b-badge>
					</div>
				</div>
			</b-navbar-brand>
		</b-navbar>
		<b-container fluid class="pagecontainer">
			<b-row v-if="error">
				<b-col>
					<b-alert variant="danger" v-if="error" show dismissible>
						ERROR: {{error}}
					</b-alert>
				</b-col>
			</b-row>
			<b-row v-if="isprocessing">
				<b-col>
					<b-spinner variant="success" label="loading..." />
				</b-col>
			</b-row>
			<b-row v-if="gameState">
				<b-col>
					<section v-if="gameState.actions">
						<b-alert class="mt-4" variant="info" show>
							<fa-icon icon="info-circle" />
							Actions:<br />
							<div class="mt-2" v-for="(action, actionIndex) in gameState.actions" :key="'action'+actionIndex">
								<fa-icon icon="user" /> {{getParticipantUserName(action.participantId)}}
								<strong>
									- {{action.message}}
								</strong>
							</div>
						</b-alert>
					</section>
					<br />
					<b-card class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<div class="d-flex py-2">
								<div class="flex-fill">
									Actions:
									<b-button-group class="pr-4" variant="success">
										<b-btn variant="primary" class="ml-2" @click="doToggleDebugGame()" v-if="!gameState.debugEnabled">Enable Debug</b-btn>
										<b-btn variant="primary" class="ml-2" @click="doToggleDebugGame()" v-else>Disable Debug</b-btn>
										<b-btn variant="primary" class="ml-2" @click="doToggleVideoGame(true)" v-if="!gameState.videoEnabled">Enable Video</b-btn>
										<b-btn variant="primary" class="ml-2" @click="doToggleVideoGame(false)" v-else>Disable Video</b-btn>
										<b-btn variant="primary" class="ml-2" @click="doNotifyGame()">Notify</b-btn>
										<b-btn variant="primary" class="ml-2" @click="doResetGame()">Reset</b-btn>
										<b-btn variant="primary" class="ml-2" @click="doStartGame()">Start</b-btn>
									</b-button-group>

									Round:
									<b-button-group>
										<b-btn variant="primary" class="ml-2" @click="doStartGameRound()">Start</b-btn>
										<b-btn variant="primary" class="ml-2" @click="doCompleteRound()">Complete</b-btn>
									</b-button-group>

									Navigations:
									<b-button-group>
										<router-link :to="{name: 'otp', params: { id } }" target="">
											<b-btn variant="primary" class="ml-2">OTP</b-btn>
										</router-link>
										<b-btn variant="primary" class="ml-2" @click="doPlayGame()">Play</b-btn>
									</b-button-group>
								</div>
							</div>
						</b-card-header>
						<b-card-body>
							<fullscreen :ref="'fullscreen'+gameState.id" @change="(fs) => fullScreenChange(gameState, fs)">
								<div class="bg-white">
									<b-tabs>
										<template v-slot:tabs-end>
											<div>
												<b-btn v-if="gameState.fullscreen" @click="toggleFullScreen(gameState)">
													<fa-icon icon="compress" />
												</b-btn>
												<b-btn v-else @click="toggleFullScreen(gameState)">
													<fa-icon icon="expand" />
												</b-btn>
											</div>
										</template>
										<b-tab lazy title="Quick View" active>
											<!--TRAYS-->
											<b-card no-body class="mb-1">
												<b-card-header header-tag="header" class="p-1" role="tab">
													<div class="d-flex">
														<div class="flex-fill h4">
															Trays
														</div>
														<div class="align-self-end">
															State: <strong>{{gameState.state_}} ({{gameState.state}})</strong>,
															Table State: <strong>{{gameState.tableState_}} ({{gameState.tableState}})</strong>
														</div>
														<div class="align-self-end">
															<b-form-select v-model.number="gameState.newTableState">
																<option value="2" selected>DEAL</option>
																<option value="3">PLAY</option>
																<option value="4">SHOW</option>
															</b-form-select>
														</div>
														<div class="align-self-end pr-2">
															<b-btn @click="doMoveStage()">Update</b-btn>
														</div>
													</div>
												</b-card-header>
												<b-card-body>
													<b-card no-body>
														<b-card-body>
															<section>
																<div class="h6">Closed Tray <b-badge>{{(gameState.dealTray && gameState.dealTray.cards ? gameState.dealTray.cards.length :0)}}</b-badge></div>
															</section>
															<section v-if="gameState.dealTray">
																<div class="d-flex flex-wrap">
																	<div class="d-flex" v-for="(card, cardIndex) in gameState.dealTray.cards" :key="cardIndex+':'+card.suit+':'+card.rank">
																		<span class="p-1">
																			<clicktocopy :text="card.id">
																				<playingcard :isFaceDown="card.suit==0 && card.rank==0" :suit="card.suit" :rank="card.rank" style="width: 20px;" />
																			</clicktocopy>
																		</span>
																	</div>
																</div>
															</section>
															<section>
																<div class="h6">Discard Tray <b-badge>{{(gameState.discardTray && gameState.discardTray.cards ? gameState.discardTray.cards.length :0)}}</b-badge></div>
															</section>
															<section v-if="gameState.discardTray">
																<div class="d-flex flex-wrap">
																	<div class="d-flex" v-for="card in gameState.discardTray.cards" :key="card.suit+':'+card.rank">
																		<span class="p-1">
																			<clicktocopy :text="card.id">
																				<playingcard :isFaceDown="card.suit==0 && card.rank==0" :suit="card.suit" :rank="card.rank" style="width: 20px;" />
																			</clicktocopy>
																		</span>
																	</div>
																</div>
															</section>
															<section class="pt-4">
																<div class="h6">
																	Joker Tray
																	<b-badge>{{(gameState.jokerTray && gameState.jokerTray.cards ? gameState.jokerTray.cards.length :0)}}</b-badge>
																</div>
																<div class="d-flex flex-wrap" v-if="gameState.jokerTray && gameState.jokerTray.cards">
																	<div class="d-flex" v-for="card in gameState.jokerTray.cards" :key="card.suit+':'+card.rank">
																		<span class="p-1">
																			<clicktocopy :text="card.id">
																				<playingcard :isFaceDown="card.suit==0 && card.rank==0" :suit="card.suit" :rank="card.rank" style="width: 20px;" />
																			</clicktocopy>
																		</span>
																	</div>
																</div>
															</section>
															<section class="pt-4">
																<div class="h6">
																	Declare Tray
																	<b-badge>{{(gameState.declareTray && gameState.declareTray.cards ? gameState.declareTray.cards.length : 0)}}</b-badge>
																</div>
																<div class="d-flex flex-wrap" v-if="gameState.declareTray && gameState.declareTray.cards">
																	<div class="d-flex" v-for="card in gameState.declareTray.cards" :key="card.suit+':'+card.rank">
																		<span class="p-1">
																			<clicktocopy :text="card.id">
																				<playingcard :isFaceDown="card.suit==0 && card.rank==0" :suit="card.suit" :rank="card.rank" style="width: 20px;" />
																			</clicktocopy>
																		</span>
																	</div>
																</div>
															</section>
															
															<section>
																<div class="h6">Bust Tray <b-badge>{{(gameState.bustTray && gameState.bustTray.cards ? gameState.bustTray.cards.length :0)}}</b-badge></div>
															</section>
															<section v-if="gameState.bustTray">
																<div class="d-flex flex-wrap">
																	<div class="d-flex" v-for="card in gameState.bustTray.cards" :key="card.suit+':'+card.rank">
																		<span class="p-1">
																			<clicktocopy :text="card.id">
																				<playingcard :isFaceDown="card.suit==0 && card.rank==0" :suit="card.suit" :rank="card.rank" style="width: 20px;" />
																			</clicktocopy>
																		</span>
																	</div>
																</div>
															</section>
														</b-card-body>
													</b-card>
												</b-card-body>
											</b-card>

											<!--PARTICIPANTS-->
											<b-card no-body class="mb-1">
												<b-card-header header-tag="header" class="p-1" role="tab">
													<div class="d-flex">
														<div class="flex-fill h4">
															Participants ({{gameState.participants.length}})
														</div>
														<div class="align-self-end">
															<b-button-group>
																<b-btn @click="doScoreRecalculate()">Recalc Scores</b-btn>
															</b-button-group>
														</div>
													</div>
												</b-card-header>
												<b-card-body>
													<b-card v-for="(participant, participantPosition) in sortedParticipants(gameState.participants)" :key="participant.id">
														<template v-slot:header>
															<div class="d-flex">
																<div class="flex-fill font-weight-bold">
																	<b-spinner v-if="isPlayerTurn(gameState, participant)" small variant="success" type="grow" label="players turn" />
																	{{participant.email}}[{{participant.userDisplayName}}] ({{participant.state_}})
																	<span class="h3">
																		<b-badge v-if="hasDeclared(participant)" class="pl-2" variant="success">
																			DECLARED <fa-icon icon="check-square" />
																		</b-badge>
																		<b-badge v-if="hasWon(participant)" class="pl-2" variant="danger">
																			WON <fa-icon icon="crown" />
																		</b-badge>
																	</span>
																</div>
																<div class="align-self-end pr-2" v-if="isDealer(gameState, participant)">
																	<b-button-group class="pt-4">
																		<b-btn @click="doShuffleClosedTray()">Shuffle Closed</b-btn>
																		<b-btn class="ml-2" @click="doDealClosedTray()">Deal Hands</b-btn>
																	</b-button-group>
																</div>
																<div class="align-self-end">
																	<b-form-select v-model.number="participant.newParticipantState">
																		<option value="0" selected>INVITED</option>
																		<option value="5">WAITING</option>
																		<option value="10">PLAYING</option>
																		<option value="20">EXITED</option>
																		<option value="40">SITTING OUT</option>
																		<option value="80">DISCONNECTED</option>
																	</b-form-select>
																</div>
																<div class="align-self-end pr-2">
																	<b-btn @click="doUpdateParticipantState(participant)">Update State</b-btn>
																</div>
																<div class="align-self-end">
																	<span v-if="isPlayerTurn(gameState, participant)" class="mx-2">
																		State: <strong>{{gameState.tableState_}}</strong>
																	</span>
																	<b-btn variant="warning" v-if="isHost(gameState, participant)">
																		host
																	</b-btn>
																	<b-btn variant="success" v-if="isDealer(gameState, participant)">
																		dealer
																	</b-btn>
																	<b-btn variant="info">
																		position: {{participantPosition}}
																	</b-btn>
																</div>
															</div>
														</template>
														<template v-slot:default>
															<section>
																<div class="h6">
																	Seat Pick Tray
																	<b-badge>{{(participant.seatPickTray && participant.seatPickTray.cards ? participant.seatPickTray.cards.length :0)}}</b-badge>
																</div>
																<div class="d-flex flex-wrap" v-if="participant.seatPickTray">
																	<div class="d-flex" v-if="gameState.seatPickTray">
																		<div class="d-flex p-2 m-1 border" v-for="(card, cardIndex) in participant.seatPickTray.cards" :key="card.suit+':'+card.rank">
																			<span class="p-1">
																				<clicktocopy :text="card.id">
																					<playingcard :isFaceDown="card.suit==0 && card.rank==0" :suit="card.suit" :rank="card.rank" style="width: 20px;" />
																				</clicktocopy>
																			</span>
																		</div>
																	</div>
																</div>
															</section>
															<section class="pt-4">
																<div class="h6">
																	<div class="d-flex" style="min-width: 350px;">
																		<div class="font-weight-bold">
																			Player Hand/Tray
																			<b-badge>{{getTotalCardsCount(participant.hand)}}</b-badge>
																		</div>
																		<div class="font-weight-bold pl-4">
																			({{(participant.hand?participant.hand.state_:null)}})
																		</div>
																		<div class="pl-2">
																			<b-form-select v-if="participant.hand"
																						   v-model.number="participant.hand.newParticipantHandState">
																				<option value="0" selected>INPLAY</option>
																				<option value="1">DECLARED</option>
																				<option value="2">WON</option>
																				<option value="3">DROPPED</option>
																				<option value="4">NOT DEALT</option>
																				<option value="5">ALL IN</option>
																			</b-form-select>
																		</div>
																		<div class="pl-2">
																			<b-btn @click="doUpdateParticipantHandState(participant)">Update</b-btn>
																		</div>
																	</div>

																</div>
																<div class="d-flex flex-wrap" v-if="participant.hand && participant.hand.groups"
																	 :id="'hand'+participant.id">
																	<b-popover triggers="hover focus"
																			   placement="top"
																			   :target="'hand'+participant.id">
																		<template #title>
																			<b-button class="close" aria-label="Close">
																				<span class="d-inline-block" aria-hidden="true">&times;</span>
																			</b-button>
																			Overall
																		</template>
																		<div>
																			<b-form-group label="Score"
																						  class="mb-1">
																				Total(System): {{participant.hand.systemCalculatedScore}}<br />
																				Total(Manual): {{participant.hand.roundBet}}
																			</b-form-group>
																		</div>
																	</b-popover>

																	<div class="d-flex p-3 pr-4 m-1 border" style="background-color: lightgray"
																		 v-for="(group, groupIndex) in participant.hand.groups"
																		 :key="'hand'+groupIndex"
																		 :id="participant.id+'handgroup'+groupIndex"
																		 :title="'group: '+ group.group + ', score: ' + group.score">

																		<div class="d-flex" v-for="(card, cardIndex) in group.cards" :key="card.suit+':'+card.rank+':'+cardIndex">
																			<span class="p-1">
																				<clicktocopy :text="card.id">
																					<playingcard :isFaceDown="card.suit==0 && card.rank==0" :suit="card.suit" :rank="card.rank" style="width: 20px;" />
																				</clicktocopy>
																			</span>
																		</div>

																		<b-popover triggers="hover focus"
																				   placement="bottom"
																				   :target="participant.id+'handgroup'+groupIndex">
																			<template #title>
																				<b-button class="close" aria-label="Close">
																					<span class="d-inline-block" aria-hidden="true">&times;</span>
																				</b-button>
																				Change Permissions - {{group.group}}
																			</template>
																			<div>
																				<b-form-group label="shared with all"
																							  class="mb-1">
																					<b-form-checkbox v-model="group.sharedWithAll" size="sm" />
																				</b-form-group>
																				<b-form-group label="shared with players">
																					<b-form-checkbox-group v-model="group.sharedWith"
																										   :options="gameState.participants"
																										   text-field="userDisplayName"
																										   value-field="id">
																					</b-form-checkbox-group>
																				</b-form-group>
																				<b-button @click="doChangeGroupPermisions(participant, group)" size="sm" variant="primary">UPDATE</b-button>
																			</div>
																		</b-popover>
																		<b-popover triggers="hover focus"
																				   placement="right"
																				   :target="participant.id+'handgroup'+groupIndex">
																			<template #title>
																				<b-button class="close" aria-label="Close">
																					<span class="d-inline-block" aria-hidden="true">&times;</span>
																				</b-button>
																				Score
																			</template>
																			<div>
																				<b-form-group label="Score"
																							  class="mb-1">
																					{{group.score}}
																				</b-form-group>
																			</div>
																		</b-popover>
																	</div>
																</div>
															</section>
															<section class="pt-4">
																<div class="h6">Play Actions</div>
																<b-form inline class="pt-4">
																	<b-form-group class="pl-2">
																		Source Tray:
																		<b-form-select v-model.number="participant.sourceTrayType">
																			<option value="0" selected>NO_TRAY</option>
																			<option value="2">DEAL_TRAY</option>
																			<option value="3">DISCARD_TRAY</option>
																			<option value="4">JOKER_TRAY</option>
																			<option value="1">SEAT_PICK_TRAY</option>
																			<option value="5">PLAYERHAND_TRAY</option>
																			<option value="6">DECLARE_TRAY</option>
																		</b-form-select>
																	</b-form-group>

																	<b-form-group class="pl-2">
																		<b-form-input style="width: 50px" type="number" v-model.number="participant.sourceCardIndex" placeholder="card index" />
																		<b-form-input style="width: 50px" v-model.trim="participant.sourceCardId" placeholder="card id, click card to copy id" />
																	</b-form-group>

																	<b-form-group class="pl-2">
																		Target Tray:
																		<b-form-select v-model.number="participant.targetTrayType">
																			<option value="0" selected>NO_TRAY</option>
																			<option value="2">DEAL_TRAY</option>
																			<option value="3">DISCARD_TRAY</option>
																			<option value="4">JOKER_TRAY</option>
																			<option value="1">SEAT_PICK_TRAY</option>
																			<option value="5">PLAYERHAND_TRAY</option>
																			<option value="6">DECLARE_TRAY</option>
																		</b-form-select>
																		<b-form-input style="width: 50px" v-model.trim="participant.targetGroupName" placeholder="optional group name" />
																	</b-form-group>
																	<b-form-group class="pl-2">
																		<b-btn size="sm" @click="doMoveAction(participant)">Move</b-btn>
																		<b-btn size="sm" variant="success" @click="doMoveNextAction(participant)">Move Next</b-btn>
																	</b-form-group>
																</b-form>
																<b-form inline class="pt-4">
																	<b-btn size="sm" variant="success" @click="doFold(participant)">Fold</b-btn>
																	<b-btn size="sm" variant="success" @click="doDeclareShow(participant)">Show</b-btn>
																	<b-form-group class="pl-4">
																		<b-form-checkbox v-model="participant.hasWon" inline>Has Won</b-form-checkbox>
																		<b-form-checkbox v-model="participant.closeGame" inline>Close Game</b-form-checkbox>
																	</b-form-group>
																	<b-form-input v-model.number="participant.score" placeholder="score" />
																	<b-btn size="sm" variant="success" @click="doDeclareChallenge(participant)">Accept/Reject</b-btn>

																	<b-form-group class="pl-4" v-if="participant.hand">
																		<b-form-input v-model.number="participant.hand.score" placeholder="score" />
																		<b-btn size="sm" variant="success" @click="doDeclareScore(participant)">Score</b-btn>
																	</b-form-group>
																</b-form>
																<b-form inline class="pt-4">
																	<b-button-group class="pt-4">
																		<b-btn @click="doMeldBySuit(participant)">Meld By Suit</b-btn>
																		<b-btn @click="doMeldByValue(participant)">Meld By Value</b-btn>
																		<b-btn @click="doRearrangeHand(participant)">Save Rearrange</b-btn>
																	</b-button-group>
																</b-form>
															</section>
														</template>
													</b-card>
												</b-card-body>
											</b-card>

											<!--SORTED CARDS-->
											<b-card no-body class="mb-1">
												<b-card-header header-tag="header" class="p-1" role="tab">
													<span class="h4">
														Default Card Sorting
													</span>
													<span class="float-right">
														<b-btn @click="doGetSortedCards()">Get</b-btn>
													</span>
												</b-card-header>
												<b-card-body>
													<section>
														<div class="d-flex flex-wrap">
															<div class="d-flex" v-for="card in defaultSortedCards" :key="card.suit+':'+card.rank">
																<span class="p-1">
																	<clicktocopy :text="card.id">
																		<playingcard :isFaceDown="card.suit==0 && card.rank==0" :suit="card.suit" :rank="card.rank" style="width: 20px;" />
																	</clicktocopy>
																</span>
															</div>
														</div>
													</section>
												</b-card-body>
											</b-card>
											<b-card no-body class="mb-1">
												<b-card-header header-tag="header" class="p-1" role="tab">
													<span class="h4">
														Seat Pick Card Sorting
													</span>
													<span class="float-right">
														<b-btn @click="doSeatPickSortedCards()">Get</b-btn>
													</span>
												</b-card-header>
												<b-card-body>
													<section>
														<div class="d-flex flex-wrap">
															<div class="d-flex" v-for="card in seatPickSortedCards" :key="card.suit+':'+card.rank">
																<span class="p-1">
																	<clicktocopy :text="card.id">
																		<playingcard :isFaceDown="card.suit==0 && card.rank==0" :suit="card.suit" :rank="card.rank" style="width: 20px;" />
																	</clicktocopy>
																</span>
															</div>
														</div>
													</section>
												</b-card-body>
											</b-card>
											<b-card no-body class="mb-1">
												<b-card-header header-tag="header" class="p-1" role="tab">
													<span class="h4">
														Seat Pick Cards
													</span>
													<span class="float-right">
														<b-btn @click="doGetSeatPickParticipantsOrder()">Get</b-btn>
													</span>
												</b-card-header>
												<b-card-body>
													<section>
														<div class="d-flex flex-wrap">
															<b-card class="mb-1" v-for="participant in seatPickParticipants" :key="participant.participant.id">
																<b-card-body>
																	Nick Name: {{participant.participant.userDisplayName}} <br />
																	Email: {{participant.participant.email}}
																</b-card-body>
																<b-card-text>
																	<div class="p-1 text-align-center" v-if="participant.dealSeatCard">
																		<playingcard :isFaceDown="participant.dealSeatCard.suit==0 && participant.dealSeatCard.rank==0"
																					 :suit="participant.dealSeatCard.suit"
																					 :rank="participant.dealSeatCard.rank"
																					 style="width: 20px;" />
																	</div>
																	<div v-else>
																		(no seat pick card)
																	</div>
																</b-card-text>
															</b-card>

														</div>
													</section>
												</b-card-body>
											</b-card>
										</b-tab>
										<b-tab lazy title="Raw">
											<vue-json-pretty v-if="gameState" :data="gameState" :deep="10" :showLength="true" />
										</b-tab>
									</b-tabs>
								</div>
							</fullscreen>
						</b-card-body>
					</b-card>
				</b-col>
			</b-row>
		</b-container>
	</section>
</template>
<script lang="js">
	import Vue from 'vue';
	import { mapState, mapActions } from 'vuex';
	import DEBUG from '@/common/DEBUG';
	import { initializeGameConnection } from "@/components/hubconnection";
	import VueJsonPretty from 'vue-json-pretty';
	import 'vue-json-pretty/lib/styles.css';
	import playingcard from '@/components/game/playingcard';
	import pass from '@/components/pass';
	import clicktocopy from '@/components/clicktocopy';
	import GameStateHelpers from '@/common/gamestatehelpers'
	import {
		ParticipantStates, ParticipantHandStates,
	} from '@/grpcservices/Game_pb';

	export default {
		name: 'manage_game',

		components: {
			pass,
			clicktocopy,
			playingcard,
			VueJsonPretty,
		},

		watch: {
			gameState: function (value) {
				DEBUG.log('PAGE GAMESTATE CHANGED...');
				this.notify(`game state updated - ${value.version}`, {
					autoHideDelay: 500,
				})
			}
		},

		data() {
			return {
				baseurl: '//' + window.location.hostname,

				id: null,
				meid: null,
				error: null,
				isprocessing: false,
				gameconnection: null,
				selectedGameStateVersion: null,
				gameState: null,
				gameStates: {},
				defaultSortedCards: null,
				seatPickSortedCards: null,
				seatPickParticipants: null,
			}
		},

		async mounted() {
			const that = this

			const id = that.id = that.$route.params.id;
			that.meid = that.userinfo.id;

			await that.doGetGame(id);

			that.gameconnection = await initializeGameConnection(that.token);
			that.gameconnection.on("GameStateChanged", function (gameState) {
				if (gameState.id == id) {
					console.log("Calling processGameState on GameStateChanged Event for game ",id);
					const ngameState = GameStateHelpers.processGameState(gameState);
					console.log('SignalR - GAMESTATECHANGED - ', ngameState)
					that.updateGameStateFlags(ngameState);
				}
			});
		},

		async beforeDestroy() {
			DEBUG.log('disconnecting from signalr')
			this.gameconnection.stop();
		},

		computed: {
			...mapState(["userinfo", "token"]),

			gameStatesVersionNumbers() {
				return Object.keys(this.gameStates).sort((a, b) => parseInt(a.version) - parseInt(b.version));
			}
		},

		methods: {
			...mapActions([
				"getGame",
				"getGameEx",
				"getDefaultSortedCards",
				"getSeatPickSortedCards",
				"getSeatPickParticipantsOrder",
				"toggleGameStateDebug",
				"toggleGameStateVideo",
				"notifyGameState",
				"createGame",
				"resetGame",
				"startGame",
				"startGameRound",
				"updateParticipantState",
				"updateParticipantHandState",
				"closedAction_DealClosedTray",
				"closedAction_ShuffleClosedTray",
				"closedAction_MoveStage",
				"closedAction_MoveCard",
				"closedAction_PlayerHandShareCardGroupArguments",
				"closedAction_MoveNext",
				"closedAction_DeclareShow",
				"closedAction_DeclareChallenge",
				"closedAction_DeclareScore",
				"closedAction_CompleteRound",
				"closedAction_Fold",
				"openAction_Rearrange",
				"openAction_MeldBySuit",
				"openAction_MeldByValue",
				"openTableAction_Score_Recalculate",
			]),

			selectGameStateVersion(version) {
				DEBUG.log('selectedGameStateVersion - changed - ', version);
				this.gameState = this.gameStates[version];
			},

			hasWon(participant) {
				return participant?.hand?.state == ParticipantHandStates.WON;
			},

			hasDeclared(participant) {
				return participant?.hand?.state == ParticipantHandStates.DECLARED;
			},

			toggleFullScreen(gameState, fullscreenid) {
				const fullscreen = this.$refs['fullscreen' + gameState.id];
				fullscreen.toggle();
			},

			fullScreenChange(gameState, fullscreen) {
				gameState.fullscreen = fullscreen
				DEBUG.log('fullScreenChange...', gameState.fullscreen, fullscreen);
			},

			getParticipantUserName(participantId) {
				return this.getParticipantById(participantId)?.userDisplayName;
			},

			getParticipantById(participantId) {
				return this.gameState?.participants?.find(p => p.id == participantId);
			},

			sortedParticipants(participants) {
				return Object.values(participants).sort((a, b) => {
					return a.position - b.position;
				});
			},

			isHost(gameState, participant) {
				return gameState.housePlayerId == participant.id;
			},

			isDealer(gameState, participant) {
				return gameState.dealerPlayerId && gameState.dealerPlayerId == participant.id;
			},

			isPlayerTurn(gameState, participant) {
				return gameState.activePlayerId == participant.id || gameState.activePlayerId == participant.id;
			},

			async doResetGame() {
				const that = this
				const id = that.id;
				DEBUG.log('doResetGame...', id)

				that.error = null;
				try {
					await that.resetGame(id);
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			addToState(gameState) {
				const that = this;
				Vue.set(that.gameStates, gameState.version, gameState);

				if (!that.selectedGameStateVersion) {
					that.selectGameStateVersion(gameState.version);
				}

				return gameState;
			},

			updateGameStateFlags(game) {
				const that = this;
				DEBUG.log('updateGameStateFlags...')
				const currentgame = that.gameState;
				const { expandstate } = currentgame ?? { expandstate: false }
				Object.assign(game, { expandstate, fullscreen: false })
				return that.addToState(game);
			},

			async doStartGame() {
				const that = this
				const id = that.id;
				DEBUG.log('doStartGame...', id)

				that.error = null;
				try {
					that.updateGameStateFlags(await that.startGame(id));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doStartGameRound() {
				const that = this
				const id = that.id;
				DEBUG.log('doStartGameRound...', id)

				that.error = null;
				try {
					that.updateGameStateFlags(await that.startGameRound(id));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doPlayGame() {
				const that = this
				const id = that.id;
				DEBUG.log('doPlayGame...', id)
				that.$router.push({ name: 'play', params: { id } })
			},

			async doGetGame() {
				const that = this
				const id = that.id;
				DEBUG.log('doGetGame...', id)

				that.error = null;
				try {
					that.updateGameStateFlags(await that.getGame(id));
					DEBUG.log('GAMESTATE - GRPC ', that.gameState)
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doGetGameEx() {
				const that = this
				const id = that.id;
				DEBUG.log('doGetGameEx...', id)

				that.error = null;
				try {
					that.updateGameStateFlags(await that.getGameEx(id));
					DEBUG.log('GAMESTATE - GRPC ', that.gameState)
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doToggleDebugGame() {
				const that = this
				const id = that.id;
				DEBUG.log('doEnableDebugGame...', id)

				that.error = null;
				try {
					const enableDebug = !that.gameState.debugEnabled;
					await that.updateGameStateFlags(await that.toggleGameStateDebug({ id, enableDebug }));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doToggleVideoGame() {
				const that = this
				const id = that.id;
				DEBUG.log('doEnableVideoGame...', id)

				that.error = null;
				try {
					const enableVideo = !that.gameState.videoEnabled;
					await that.updateGameStateFlags(await that.toggleGameStateVideo({ id, enableVideo }));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doNotifyGame() {
				const that = this
				const id = that.id;
				DEBUG.log('doNotifyGame...', id)

				that.error = null;
				try {
					await that.updateGameStateFlags(await that.notifyGameState(id));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doDealClosedTray() {
				const that = this
				const id = that.id;
				DEBUG.log('doDealClosedTray...', id)

				that.error = null;
				try {
					that.updateGameStateFlags(await that.closedAction_DealClosedTray({
						gameId: id,
					}));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doShuffleClosedTray() {
				const that = this
				const id = that.id;
				DEBUG.log('doShuffleClosedTray...', id)

				that.error = null;
				try {
					that.updateGameStateFlags(await that.closedAction_ShuffleClosedTray({
						gameId: that.gameState.id,
					}));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doMoveStage() {
				const that = this
				DEBUG.log('doMoveStage...')

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.id,
						tableState: that.gameState.newTableState,
					};
					that.updateGameStateFlags(await that.closedAction_MoveStage(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doUpdateParticipantState(participant) {
				const that = this
				DEBUG.log('doUpdateParticipantState...', participant)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.id,
						playerId: participant.id,
						participantState: participant.newParticipantState,
					};
					that.updateGameStateFlags(await that.updateParticipantState(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doUpdateParticipantHandState(participant) {
				const that = this
				DEBUG.log('doUpdateParticipantHandState...', participant)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.id,
						playerId: participant.id,
						participantHandState: participant.hand.newParticipantHandState,
					};
					that.updateGameStateFlags(await that.updateParticipantHandState(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doChangeGroupPermisions(participant, group) {
				const that = this
				

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.id,
						playerId: participant.id,
						group: group.group,
						sharedWithAll: group.sharedWithAll,
						sharedWith: group.sharedWith,
					};
					DEBUG.log('doChangeGroupPermisions...', invokeArgs)
					that.updateGameStateFlags(await that.closedAction_PlayerHandShareCardGroupArguments(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doMoveAction(participant) {
				const that = this
				DEBUG.log('doMoveAction...', participant)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: participant.id,
						sourceTrayType: participant.sourceTrayType,
						sourceCardIndex: participant.sourceCardIndex,
						sourceCardId: participant.sourceCardId,
						targetTrayType: participant.targetTrayType,
						targetGroupName: participant.targetGroupName,
					};
					that.updateGameStateFlags(await that.closedAction_MoveCard(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doMoveNextAction(participant) {
				const that = this
				DEBUG.log('doMoveNextAction...', participant)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: participant.id,
					};
					that.updateGameStateFlags(await that.closedAction_MoveNext(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doDeclareShow(participant) {
				const that = this
				DEBUG.log('doDeclareShow...', participant)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: participant.id,
					};
					that.updateGameStateFlags(await that.closedAction_DeclareShow(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doDeclareChallenge(participant) {
				const that = this
				DEBUG.log('doDeclareChallenge...', participant)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: participant.id,
						hasWon: participant.hasWon,
						closeGame: participant.closeGame,
						score: participant.score,
					};
					that.updateGameStateFlags(await that.closedAction_DeclareChallenge(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doCompleteRound() {
				const that = this
				DEBUG.log('doCompleteRound...')

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
					};
					that.updateGameStateFlags(await that.closedAction_CompleteRound(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doDeclareScore(participant) {
				const that = this
				DEBUG.log('doDeclareScore...', participant)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						forPlayerId: participant.id,
						score: participant.hand.score,
					};
					that.updateGameStateFlags(await that.closedAction_DeclareScore(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doFold(participant) {
				const that = this
				DEBUG.log('doFold...', participant)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: participant.id,
					};
					that.updateGameStateFlags(await that.closedAction_Fold(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doRearrangeHand(participant) {
				const that = this
				DEBUG.log('doRearrangeHand...', participant)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: participant.id,
						groups: participant.hand.groups,
					};
					that.updateGameStateFlags(await that.openAction_Rearrange(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doMeldBySuit(participant) {
				const that = this
				DEBUG.log('doMeldBySuit...', participant)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: participant.id,
					};
					that.updateGameStateFlags(await that.openAction_MeldBySuit(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doMeldByValue(participant) {
				const that = this
				DEBUG.log('doMeldByValue...', participant)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: participant.id,
					};
					that.updateGameStateFlags(await that.openAction_MeldByValue(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doScoreRecalculate() {
				const that = this
				DEBUG.log('do_Score_Recalculate...')

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
					};
					that.updateGameStateFlags(await that.openTableAction_Score_Recalculate(invokeArgs));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doGetSortedCards() {
				const that = this
				DEBUG.log('doGetSortedCards...',)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
					};
					that.defaultSortedCards = await that.getDefaultSortedCards(invokeArgs);
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doSeatPickSortedCards() {
				const that = this
				DEBUG.log('doSeatPickSortedCards...',)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
					};
					that.seatPickSortedCards = await that.getSeatPickSortedCards(invokeArgs);
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async doGetSeatPickParticipantsOrder() {
				const that = this
				DEBUG.log('doGetSeatPickParticipantsOrder...',)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
					};
					that.seatPickParticipants = await that.getSeatPickParticipantsOrder(invokeArgs);
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			getTotalCardsCount(tray) {
				let cards = 0;
				tray?.groups?.forEach(g => {
					cards += g.cards.length;
				})
				return cards;
			},

			messageCallback(message, participant) {
				DEBUG.log('RECEIVED PLAY MESSAGE - ', message, participant);
			},
		}
	}
</script>
<style>
	.pagecontainer {
		max-height: 85vh;
		overflow-y: auto;
	}
</style>